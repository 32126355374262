import { ThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import Navigation from "./components/Navigation/Navigation";
import { lightTheme } from "./themes";
import { FormspreeProvider } from "@formspree/react";
import loadable from "@loadable/component";
import ScrollToTop from "react-scroll-up";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const ContactPage = loadable(() => import("./pages/ContactPage/ContactPage"));
const GalleryPage = loadable(() => import("./pages/GalleryPage/GalleryPage"));
const HomePage = loadable(() => import("./pages/HomePage/HomePage"));
const PortfolioPage = loadable(
  () => import("./pages/PortfolioPage/PortfolioPage")
);
const ProjectPage = loadable(() => import("./pages/ProjectPage/ProjectPage"));
// import { Provider } from "react-redux";
// // We'll create this in step 3.
// import store from "./store.tsx";

function App() {
  const [lightMode, setLightMode] = useState<"default" | "inverted" | null>(
    null
  );

  useEffect(() => {
    const localTheme =
      lightMode || window.localStorage.getItem("lightMode") || "default";
    setLightMode(localTheme as typeof lightMode);
    if (localTheme === "inverted") {
      document.body.classList.add("is_inverted");
    } else {
      document.body.classList.remove("is_inverted");

      // PortfolioGridSection.classList.remove("is_inverted");
    }
  }, [lightMode]);

  return (
    <div className="App">
      <FormspreeProvider project="1717265022628920649">
        <ThemeProvider theme={lightTheme}>
          <HashRouter>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Navigation />
            <Switch>
              <Route exact path="/home">
                <HomePage />
              </Route>
              <Route path="/portfolio">
                <PortfolioPage />
              </Route>
              <Route path="/project/:project">
                <ProjectPage />
              </Route>
              <Route path="/gallery">
                <GalleryPage />
              </Route>
              <Route path="/contact">
                <ContactPage />
              </Route>
            </Switch>
          </HashRouter>
          <ScrollToTop style={{ zIndex: 100000 }} showUnder={160}>
            <span className="scrollUp">
              <ArrowUpwardIcon />
            </span>
          </ScrollToTop>
        </ThemeProvider>
      </FormspreeProvider>
    </div>
  );
}

export default App;
