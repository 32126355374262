import React from "react";
import styles from "./Navigation.module.scss";
import { Link, withRouter } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";

const Navigation = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={styles.Navigation} data-testid="Navigation">
      <nav className={`${styles.siteNav} ${styles.desktopOnly}`}>
        <ul className={styles.navList}>
          <li
            className={`nav-item  ${
              props.location.pathname === "/home" ? styles["active"] : ""
            }`}
          >
            <Link to="/home">Home</Link>
          </li>
          <li
            className={`nav-item  ${
              props.location.pathname === "/portfolio" ||
              props.location.pathname === "/project"
                ? styles["active"]
                : ""
            }`}
          >
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li
            className={`nav-item  ${
              props.location.pathname === "/gallery" ? styles["active"] : ""
            }`}
          >
            <Link to="/gallery">Gallery</Link>
          </li>
          <li
            className={`nav-item  ${
              props.location.pathname === "/contact" ? styles["active"] : ""
            }`}
          >
            <Link to="/contact">Contact</Link>
          </li>
          {/* <li>
            <a href="https://forms.gle/MfHo8xuPqPTvprBV6">Request a quote</a>
          </li>
          <li>
            <a href="https://saradelessio.bigcartel.com/">Big cartel shop</a>
          </li>
          <li>
            <a href="https://drive.google.com/file/d/0B2D4cWUYO8ZHS2RpQzhWQkU1MTA/view?usp=sharing&resourcekey=0-HWoV839KBFJ-2TNVrQwX0Q">
              Resume
            </a>
          </li> */}
          {/* <li>
          <a href="https://drive.google.com/file/d/12zjrBzg-FWQOFUe4fPBHmbL6HMqvy6vB/view?usp=sharing">
            Rates
          </a>
        </li> */}
        </ul>
      </nav>
      <nav className={`${styles.siteNav} ${styles.mobileOnly}`}>
        <Link
          className={`nav-item  ${
            props.location.pathname === "/contact" ? styles["active"] : ""
          }`}
          to="/contact"
        >
          Contact
        </Link>
        <button
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </button>
        <Menu
          className={styles.navList}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem
            className={`nav-item  ${
              props.location.pathname === "/home" ? styles["active"] : ""
            }`}
          >
            <Link to="/home">Home</Link>
          </MenuItem>
          <MenuItem
            className={`nav-item  ${
              props.location.pathname === "/portfolio" ||
              props.location.pathname === "/project"
                ? styles["active"]
                : ""
            }`}
          >
            <Link to="/portfolio">Portfolio</Link>
          </MenuItem>
          <MenuItem
            className={`nav-item  ${
              props.location.pathname === "/gallery" ? styles["active"] : ""
            }`}
          >
            <Link to="/gallery">Gallery</Link>
          </MenuItem>
          {/* <MenuItem className="nav-item">
            <a href="https://forms.gle/MfHo8xuPqPTvprBV6">Request a quote</a>
          </MenuItem> */}
          {/* <MenuItem className="nav-item">
            <a href="https://saradelessio.bigcartel.com/">Big cartel shop</a>
          </MenuItem> */}
          {/* <MenuItem className="nav-item">
            <a href="https://drive.google.com/file/d/0B2D4cWUYO8ZHS2RpQzhWQkU1MTA/view?usp=sharing&resourcekey=0-HWoV839KBFJ-2TNVrQwX0Q">
              Resume
            </a>
          </MenuItem> */}

          {/* <li>
              <a href="https://drive.google.com/file/d/12zjrBzg-FWQOFUe4fPBHmbL6HMqvy6vB/view?usp=sharing">
                Rates
              </a>
            </li> */}
        </Menu>
      </nav>
    </div>
  );
};

export default withRouter(Navigation);
