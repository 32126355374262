// import {blue} from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
//NOTE afaik, this is the best way to incluge the labs theme augmentation - https://material-ui.com/components/about-the-lab/#typescript
// import {
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   LabComponentNameToClassKey,
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   LabComponentsPropsList,
// } from "@material-ui/lab/themeAugmentation";

export const BREAKPOINTS = {
  sm: "(max-width:960px)",
  lg: "(max-width:1380px)",
};

export const lightTheme = createMuiTheme({
  palette: {
    type: "light",
    action: {
      selected: "#354B7A",
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#354B7A",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
  },
  overrides: {
    MuiList: {
      root: {
        backgroundColor: "white",
      },
    },
  },
});
